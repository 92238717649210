import router from "@/router";
import axios from "axios";
const state = {
  token: localStorage.getItem("token") || null
};

const getters = {
  getToken: state => {
    return state.token;
  }
};

const actions = {
  setToken({ commit }, token) {
    return new Promise(resolve => {
      let header_token = `Bearer ${token}`;
      commit("SET_TOKEN", header_token);
      resolve(header_token);
    });
  },
  checkAuth({ commit, getters }) {
    return new Promise((resolve, reject) => {
      // let header_token = getters.getToken;
      // if (!header_token) {
      //   return reject({ success: false });
      // }
      // axios
      //   .get(`${process.env.VUE_APP_BACKEND_API_URL}/wallet/${token}`, {
      //     headers: { Authorization: header_token }
      //   })
      //   .then(({ data }) => {
      //     if (data.success) {
      //       commit("UPDATE_USER_INFO", { displayName: data.username });
      //     } else {
      //       commit("DEL_TOKEN");
      //       reject(data);
      //       console.log("logout");
      //       return router.push("/logout");
      //     }
      //     return resolve(data);
      //   })
      //   .catch(err => {
      //     commit("DEL_TOKEN");
      //     return reject(err);
      //   });
    });
  },
  async logout({ commit, getters }) {
    try {
      let header_token = getters.getToken;
      // await axios.post(
      //   `${process.env.VUE_APP_BACKEND_API_URL}/logout`,
      //   {},
      //   {
      //     headers: { Authorization: header_token }
      //   }
      // );
      commit("DEL_TOKEN");
      router.push("/login");
    } catch (error) {
      commit("DEL_TOKEN");
      router.push("/login");
      throw error;
    }
  }
};

const mutations = {
  SET_TOKEN(state, token) {
    localStorage.setItem("token", token);
    state.token = token;
  },
  DEL_TOKEN(state) {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    // localStorage.removeItem("trialTimeUp");
    // if (localStorage.getItem("trial_login") === "true") {
    //   localStorage.setItem("trial_active", true);
    //   localStorage.removeItem("trial_login");
    // }

    state.token = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
