const userDefaults = {
  user_id: 0, // From Auth
  username: "",
  displayName: "John Doe", // From Auth
  status: "online",
  userRole: "admin"
};
const userData = JSON.parse(localStorage.getItem("userInfo"));

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////
const state = {
  AppActiveUser: userData!=null?userData:userDefaults,
  test: 123
};

export default state;
