import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store/store";

// const router = new VueRouter({
//   routes, // short for routes: routes
//   linkExactActiveClass: "active",
//   scrollBehavior: (to) => {
//     if (to.hash) {
//       return {selector: to.hash}
//     } else {
//       return { x: 0, y: 0 }
//     }
//   }
// });

// export default router;

// configure router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path != from.path) {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  // some function to check token if request was sent.

  if (store.getters.getToken) {
    if (to.path == "/login") next("/home");
    next();
  } else if (
    !store.getters.getToken &&
    to.path != "/login" &&
    to.path != "/reset" &&
    to.path != "/forget"
  ) {
    store
      .dispatch("logout")
      .then(r => console.log(r))
      .catch(error => console.log(error));
    // next("/login");
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
