<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    },
  },
  mounted() {
    this.$watch("$route", this.disableRTL, { immediate: true });
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);
  },
};
</script>

<style lang="scss">
h5 {
  font-size: 16px !important;
}
.btn {
  font-size: 16px !important;
}
input {
  border-radius: 4px !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 0 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  border-color: transparent !important;
  background-color: #7999f1 !important;
  color: #ffffff !important;
  font-size: 16px !important;
}
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
}
input::placeholder {
  color: rgb(230, 230, 230) !important;
  opacity: 1 !important;
}

input:-ms-input-placeholder {
  color: rgb(230, 230, 230) !important;
  opacity: 1 !important;
}

input::-ms-input-placeholder {
  color: rgb(230, 230, 230) !important;
  opacity: 1 !important;
}

.circle-icon {
  height: 50px;
  width: 50px;

  border-radius: 50%;

  position: absolute;

  left: 50%;

  top: -25px;
  z-index: 100;
}
.center {
  position: absolute;

  left: 50%;

  top: 50%;

  transform: translate(-50%, -50%);
}
</style>
