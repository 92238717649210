/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
import axios from "./axios.js";
import store from "./store/store";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import "./registerServiceWorker";
import VueSocketIO from "vue-socket.io";
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.component("apexchart", VueApexCharts);

Vue.prototype.$http = axios;
Vue.prototype.$storex = store;

const vuesocket = new VueSocketIO({
  debug: process.env.NODE_ENV !== "production" && false,
  connection: process.env.VUE_APP_BACKEND_API_URL || "http://localhost:8080",
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  }
});

Vue.use(vuesocket);

// const socketPublic = {};

// const compo = {
//   $options: {
//     name: "ALL"
//   }
// };
// socketPublic.subscribe = (event, callback) => {
//   vuesocket.emitter.addListener(event, callback, compo);
// };

// socketPublic.unsubscribe = event => {
//   vuesocket.emitter.removeListener(event, compo);
// };

// Vue.prototype.$socketPublic = socketPublic;

const checkAuthInterval = setInterval(async () => {
  try {
    //console.log("checked");
    await store.dispatch("checkAuth");
  } catch (e) {}
}, 5000);

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  created() {
    window.addEventListener("beforeunload", () => {
      clearInterval(checkAuthInterval);
      store.dispatch("pauseBaccaratBotAll");
      store.dispatch("pauseDragonTigerBotAll");
      store.dispatch("pauseRouletteBotAll");
    });
  }
}).$mount("#app");
