/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const mutations = {
  UPDATE_USER_INFO(state, payload) {
    // console.log(payload);
    // Get Data localStorage
    let userInfo =
      JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser;

   
    for (const property of Object.keys(payload)) {
    
      if (payload[property] != null) {
       
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property];

        // Update key in localStorage
        userInfo[property] = payload[property];
        
     
      }
    }
    // Store data in localStorage
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }
};

export default mutations;
