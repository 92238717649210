import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// import Login from "@/views/Layout.vue";
import Layout from "@/views/Layout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");

const Login = () =>
  import(/* webpackChunkName: "common" */ "@/views/Login.vue");
const Logout = () =>
  import(/* webpackChunkName: "common" */ "@/views/Logout.vue");

const Home = () => import(/* webpackChunkName: "common" */ "@/views/Home.vue");

const Room = () =>
  import(/* webpackChunkName: "common" */ "@/views/room/Room.vue");

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: Home
      },
      {
        path: "login",
        name: "login",
        component: Login,
        meta: {
          requiresAuth: true,
          navbar: false
        }
      },
      {
        path: "/logout",
        name: "logout",
        component: Logout
        // meta: {
        //   layout: "no-wrapper",
        // },
      },
      {
        path: "room/:botType",
        name: "room",
        component: Room,
        props: route => ({
          wallet: route.params
        })
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList
      }
    ]
  },

  { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
